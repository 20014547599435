import { MutationPayload } from 'vuex';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import Textarea from '@/components/form/textarea.vue';
import Loading from '@/components/loading.vue';
import Popup from '@/components/popup.vue';
import { IExhibitorsState, IStateExhibitor, IExhibitorsParams } from '@/store/modules/exhibitors';
import isMobileDevice from '@/utils/isMobileDevice';
import store from '@/store';
import ExhibitorCard from '@/partials/ExhibitorCard.vue';
import { IExhibitor } from '@/models/exhibitor';
import { IExhibitorCard } from '@/partials/index';
import { getExhibitors } from '@/api/exhibitors';
import { ICategory } from '@/models/category';
import { getExhibitorCategories } from '@/api/categories';
import router from '@/router';
import { filters } from './index';
import { computed, defineComponent } from '@vue/composition-api';

import useContext from '@/composition/context';
const staging = process.env.VUE_APP_SERVER_PATH === 'https://b.beamian.com/api';

export default defineComponent({
  components: {
    Input,
    Textarea,
    Popup,
    Loading,
    Select,
    ExhibitorCard,
  },
  data() {
    return {
      currentPage: 1,
      filters: filters(),
      layout: isMobileDevice() ? 'cards' : 'table',
      event: 0,
      searchTimeout: 0,
      searchString: '',
      filterString: '',
      exhibitorsT1: [] as IExhibitor[],
      exhibitorsT2: [] as IExhibitor[],
      categories: [] as ICategory[],
      selectedCategory: 0,
      loading: false,
    }
  },
  setup() {
    const { contextEvent, contextVisitor, translations } = useContext();

    const exhibitorsState = computed(() => store.getters.getExhibitorsState as IExhibitorsState);

    const exhibitors = computed(() => store.getters.getExhibitors as IStateExhibitor[]);

    return {
      contextEvent, contextVisitor, translations, exhibitorsState, exhibitors
    }
  },
  created() {
    this.event = parseInt(this.$route.params.event, 10);

    this.selectedCategory = parseInt(router.currentRoute.query.category as string, 10);

    const categoriesParams = {
      event: this.event as number,
    };

    getExhibitorCategories(categoriesParams).then((response) => {
      this.categories = response.data.results;
    });

    this.getExhibitors();

    this.initFilters();
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        this.initFilters();
      }
    });
  },
  methods: {

    getExhibitors() {
      const params: IExhibitorsParams = {
        page: 1,
        event: this.event as number,
        page_size: 30,
        reset: true,
        sponsor_level: 'ds',
        category: this.selectedCategory ? this.selectedCategory as number : undefined,
        hidden: false,
        status: 'registered',
      };

      store.dispatch('fetchExhibitors', params);

      params.sponsor_level = 't1';
      params.page_size = 500;

      getExhibitors(params).then((response) => {
        this.exhibitorsT1 = response.data.results;
      });

      params.sponsor_level = 't2';

      getExhibitors(params).then((response) => {
        this.exhibitorsT2 = response.data.results;
      });
    },

    categoryActive(category: string) {
      if (router.currentRoute.fullPath.includes(`category=${category}`)) {
        return true;
      }

      return false;
    },

    initFilters() {
      this.filters = filters();
      this.filters.search.onInput = this.search;
      this.filters.interaction.onChange = this.filter;
    },

    nextPage() {
      if (this.exhibitorsState.page) {
        const params: IExhibitorsParams = {
          page: this.exhibitorsState.page + 1,
          event: this.contextEvent.id,
          page_size: 30,
          sponsor_level: 'ds',
          hidden: false,
          status: 'registered',
        };

        if (this.searchString) {
          params.search = this.searchString;
        }

        if (this.filterString === 'interacted') {
          params.visitor = this.contextVisitor.id;
        } else if (this.filterString === 'interacted-not') {
          params.notvisitor = this.contextVisitor.id;
        }

        store.dispatch('fetchExhibitors', params);
      }
      store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setLanguage') {
          this.filters = filters();
        }
      });
    },

    search(value: string | number) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        const params: IExhibitorsParams = {
          page: 1,
          event: this.contextEvent.id,
          page_size: 18,
          search: value as string,
          reset: true,
          sponsor_level: 'ds',
          hidden: false,
          status: 'registered',
        };

        // Get current filters
        if (this.filterString === 'interacted') {
          params.visitor = this.contextVisitor.id;
        } else if (this.filterString === 'interacted-not') {
          params.notvisitor = this.contextVisitor.id;
        }

        store.dispatch('fetchExhibitors', params);

        params.sponsor_level = 't1';

        getExhibitors(params).then((response) => {
          this.exhibitorsT1 = response.data.results;
        });

        params.sponsor_level = 't2';

        getExhibitors(params).then((response) => {
          this.exhibitorsT2 = response.data.results;
        });

        this.searchString = value as string;
      }, 1000);
    },

    filter(value: string) {
      const params: IExhibitorsParams = {
        page: 1,
        event: this.contextEvent.id,
        page_size: 18,
        search: this.searchString !== '' ? this.searchString : undefined,
        reset: true,
        sponsor_level: 'ds',
        hidden: false,
        status: 'registered',
      };
      if (value === 'interacted') {
        params.visitor = this.contextVisitor.id;
      } else if (value === 'interacted-not') {
        params.notvisitor = this.contextVisitor.id;
      } else if (value === 'favorites') {
        params.favorite = true;
      }

      store.dispatch('fetchExhibitors', params);
      this.filterString = value as string;

      params.sponsor_level = 't1';

      getExhibitors(params).then((response) => {
        this.exhibitorsT1 = response.data.results;
      });

      params.sponsor_level = 't2';

      getExhibitors(params).then((response) => {
        this.exhibitorsT2 = response.data.results;
      });
    },

    getCroppedImage(url: string | undefined) {
      if (!url || url === '') {
        return undefined;
      }

      if (staging) {
        return url;
      }
      const bucketUrl = 'f.beamian.com';
      const indexOfBucket = url.indexOf(bucketUrl);

      const imagePath = url.substr(indexOfBucket + bucketUrl.length + 1, url.length);

      return `https://d2jgquhpz530bi.cloudfront.net/300x300/${imagePath}`;
    },

    cardConfig(exhibitor: IExhibitor) {
      const config: IExhibitorCard = {
        name: exhibitor.name,
        subtitle: exhibitor.card ? exhibitor.card.subtitle : undefined,
        description: exhibitor.card ? exhibitor.card.description : undefined,
        logo: this.getCroppedImage(exhibitor.logo_url),
        route: `/event/${this.event}/exhibitor/${exhibitor.id}`,
      };
      return config;
    }
  },

});

