import { IModal, IOffcanvas, IPopup } from '@/components';
import {
  ICheckbox, IInput, ITextarea, ISelect,
} from '@/components/form';
import { IExhibitor } from '@/models/exhibitor';
import router from '@/router';
import store from '@/store';
import { ITranslations } from '@/translations/index';

export const form: (translations: any) => IExhibitorForm = () => ({
  comment: {
    id: 'exhibitor-comment',
    name: 'exhibitor_name',
    placeholder: 'Enter comment for exhibitor',
    value: '',
  },
});

export const exhibitorActionPopup: IPopup = {
  message: '',
  type: '',
};

export interface IExhibitorForm {
  comment: ITextarea;
}

export interface IExhibitorBookletForm {
  title: IInput;
  cover: IInput;
  is_published: ICheckbox;
}

export const exhibitorsModal: IModal = {
  isActive: true,
  isCard: true,
  close: () => {
    router.push(
      `/event/${store.getters.getContextEvent.id}/exhibitors`,
    );
  },
};

export const exhibitorsOffcanvas: IOffcanvas = {
  isActive: true,
  close: () => {
    router.push(
      `/event/${store.getters.getContextEvent.id}/exhibitors`,
    );
  },
};

export const exhibitorModel: Partial<IExhibitor> = {
};

export interface IVisitorExhibitorFilters {
  interaction: ISelect;
  search: IInput;
}

export const filters: () => IVisitorExhibitorFilters = () => {
  const translations = store.getters.getTranslations as ITranslations;
  const filters: IVisitorExhibitorFilters = {
    search: {
      id: 'filter-search',
      name: 'filter_search',
      value: '',
      placeholder: translations.common.search,
      type: 'text',
    },
    interaction: {
      id: 'filter-interaction',
      name: 'filter_interaction',
      value: 'all',
      onChange: (value: string) => {
        if (value === 'interacted') {
          store.dispatch('fetchExhibitorsByVisitorId', store.getters.getContextVisitor.id);
        }
      },
      options: [
        {
          value: 'all',
          label: translations.visitors.exhibitors.all_exhibitors,
        },
        {
          value: 'favorites',
          label: translations.common.favorites,
        },
        {
          value: 'interacted',
          label: translations.visitors.exhibitors.interacted_exhibitors,
        },
        {
          value: 'interacted-not',
          label: translations.visitors.exhibitors.not_interacted_exhibitors,
        },

      ],
    },
  };
  return filters;
};
